const scrollBodyToTop = (): void => {
  document.body.scroll(0, 0);
};

const scrollElemToTop = (elementRef: Ref<HTMLElement | null>): void => {
  if (elementRef.value) {
    elementRef.value.scroll(0, 0);
  }
};

export { scrollBodyToTop, scrollElemToTop };
